import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useAccount } from '@frontastic-engbers/lib';
import { getCleverPushConfig } from '@frontastic-engbers/lib/actions/config';
import { CleverPushConfigResponse } from '@frontastic-engbers/types/engbers-custom';
interface CleverPushProps {
  forceChannelId?: string;
}
export const CleverPush = ({
  forceChannelId
}: CleverPushProps) => {
  const {
    loggedIn,
    account
  } = useAccount();
  const [cleverPushConfig, setCleverPushConfig] = useState<CleverPushConfigResponse>(null);

  // We need to mind all the different cases when to set the user_id
  // Make sure to only push the attribute
  useEffect(() => {
    if (loggedIn && account) {
      // If CleverPush is already initialized, we can try to set the user ID right away
      // whenever loggedIn or account change and are set
      // This usually happens after log in
      if (window.CleverPush) {
        window.CleverPush.push(['setAttribute', 'user_id', account.cardId]);
      }
    }

    // Docs: https://developers.cleverpush.com/docs/sdks/javascript/methods
    // Waiting for the initialization of CP to push the user ID
    // Also adding an eveng to wait for the subscription to push the user_id
    window.cleverPushInitCallback = () => {
      if (account && account.cardId) {
        window.CleverPush.push(['setAttribute', 'user_id', account.cardId]);
      }
      window.CleverPush.on('subscribed', async () => {
        if (account && account.cardId) {
          window.CleverPush.push(['setAttribute', 'user_id', account.cardId]);
        }
      });
    };
  }, [loggedIn, account]);
  useEffect(() => {
    const getConfig = async () => {
      const config = await getCleverPushConfig();
      setCleverPushConfig(config);
    };
    getConfig();
  }, []);

  // The forced channel id always overwrites the one gotten from the API config
  const channelId = forceChannelId ? forceChannelId : cleverPushConfig?.channelId;
  if (!channelId) {
    return null;
  }
  return <Script src={`https://static.cleverpush.com/channel/loader/${channelId}.js`} data-sentry-element="Script" data-sentry-component="CleverPush" data-sentry-source-file="index.tsx" />;
};