import React from 'react';
import { ModalWrap } from '@engbers/components/shopmacher-ui/modal-wrap';
import styles from './layout.module.scss';
import { RecoilRoot } from 'recoil';
import { NotificationWrap } from '@engbers/components/shopmacher-ui/notification-wrap';
interface ILayout {
  children: React.ReactNode;
}

// The layout only makes sure
// that the whole content can't run under the header / footer
export const Layout: React.FC<ILayout> = ({
  children
}) => {
  return <RecoilRoot data-sentry-element="RecoilRoot" data-sentry-component="Layout" data-sentry-source-file="index.tsx">
      <div className={styles.layout}>
        <div>{children}</div>
        <ModalWrap data-sentry-element="ModalWrap" data-sentry-source-file="index.tsx" />
        <NotificationWrap data-sentry-element="NotificationWrap" data-sentry-source-file="index.tsx" />
      </div>
    </RecoilRoot>;
};